<script setup>
import { useTitle } from '@vueuse/core';
import { i18n } from '@/i18n';
import { useRoute, useRouter } from 'vue-router/composables';
import { useStore } from '@/store';
import columns from './columns';
import { useAxiosClient } from '@/composable/useFetch';
import { useCurrency } from '@/composable/useCurrency';
import { getCurrentInstance } from 'vue';
import { updateProductStatus } from '@/api/channels/store'

useTitle(`${i18n.t('product.listProduct')} Web Commerce - Powerbiz`)

const colors = {
  ACTIVE: 'blue',
  UNCONFIRMED: 'orange',
  INACTIVE: 'red',
  NOT_ACTIVE: 'red',
}

const route = useRoute()
const router = useRouter()
const store = useStore()

const { $confirm, $message } = getCurrentInstance().proxy

const { format } = useCurrency()

const DEFAULT_PAGE_SIZE = 25

const { data: product,  error, isLoading, isFinished: isReady, execute } = useAxiosClient(
  'channel/store/product/query',
  () => ({
    params: {
      ...route.query,
      channel_id: route.params.channelId,
    },
    headers: {
      'user-id': store.state.user?.id,
      'business-id': route.query.business_id,
    },
  }),
  {
    immediate: true,
    resetOnExecute: false,
    shallow: true,
    watch: () => route.query,
    onFinish: () => document.querySelector('.ant-table-body')?.scroll({ behavior: 'smooth', top: 0 }),
    initialData: {
      data: [],
      total_record: 0,
    },
  },
)

const priceLabel = (item) => {
  const prices = [
    item.min_sellingprice,
  ]

  if (item.max_sellingprice !== item.min_sellingprice) prices.push(item.max_sellingprice)

  return prices.map(price => format(price, 'IDR')).join(' - ')
}

const onSearch = (val) => {
  router.push({ query: { ...route.query, q: val, page: 1 } })
}

async function toggleProductStatus(id, state) {
  try {
    const res = await updateProductStatus({
      business_id: route.query.business_id,
      user_id: store.state.user?.id,
      product_id: id,
      state,
      params: {
        ...route.query,
        channel_id: route.params.channelId,
      },
    })
    await execute()
  } catch (err) {
    $message.error(err?.response?.message || err?.message)
  }
}

async function showModalUpdate(product) {
  const state = (product.status || '').toUpperCase() === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'

  if (state === 'ACTIVE') {
    await toggleProductStatus(product.product_id, state)
  } else {

    $confirm({
      title: 'Non-aktifkan produk',
      content: 'Apakah anda yakin ingin menonaktifkan produk ini ?',
      onOk: async () => await toggleProductStatus(product.product_id, state),
    })
  }
}

const onImageError = (e) => {
  e.target.src = 'https://ik.imagekit.io/powerbiz/no-image.png'
}
</script>

<template>
  <div class="store-product-list-page">
    <h1 class="h4">
      {{ `${$t('product.listProduct')}` }} - WebCommerce
    </h1>
    <div class="row mt-4 mb-4">
      <div class="col-md-5">
        <a-input-search
          :default-value="$route.query.q"
          size="large"
          :placeholder="$t('store_list_page.search_products')"
          :loading="isLoading"
          allow-clear
          @search="onSearch"
        >
          <a-button slot="enterButton" type="danger" icon="search">
            {{ $t('utils.search') }}
          </a-button>
        </a-input-search>
      </div>
    </div>


    <div class="bg-white mb-4" style="overflow-x: auto">
      <a-table
        :data-source="product.data"
        :row-key="(record, index) => `${record.product_id}_${index}`"
        :columns="columns"
        :loading="isLoading"
        :pagination="false"
        class="mb-4"
      >
        <template #showimg_url="_text, record">
          <RouterLink
            :to="{
              name: 'channel.store.product.edit',
              params: { id: record.product_id },
              query: {
              ...$route.query,
              redirect_url: $route.fullPath,
              master_product: true,
              },
            }"
            class="d-flex align-items-center product-title"
          >
            <div>
              <img
                :src="`${record.thumbimg_url}?tr=w-50,h-50,cm-pad_resize,bg-FFFFFF`"
                class="product-image mr-3"
                draggable="false"
                width="35"
                height="35"
                @error="onImageError"
              />
            </div>
            <div>
              {{ record.title }}
            </div>
          </RouterLink>
        </template>
        <template slot="category_name" slot-scope="text">
          {{ text || '-' }}
        </template>
        <template #max_sellingprice="_text, record">
          <div>
            {{ priceLabel(record) }}
          </div>
        </template>
        <template slot="status" slot-scope="text">
          <a-tag :color="colors[text]">
            {{ $t(`product.productStatus.${text}`) }}
          </a-tag>
        </template>
        <template slot="action" slot-scope="record">
          <div class="text-right">
            <a-dropdown>
              <a @click.prevent="">
                <a-icon type="more" />
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                    key="2"
                    class="py-2"
                    @click="$router.push({
                      name: 'channel.store.product.edit',
                      params: { id: record.product_id },
                      query: {
                      ...$route.query,
                      redirect_url: $route.fullPath,
                      master_product: true,
                      },
                    })"
                  >
                    Lihat Detail
                  </a-menu-item>
                  <a-menu-item key="3" class="py-2" @click="showModalUpdate(record)">
                    {{
                      record.product_status === 'ACTIVE' ||
                        record.status === 'ACTIVE'
                        ? $t('store_list_page.deactivate_product')
                        : $t('store_list_page.activate_product')
                    }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </template>
      </a-table>
    </div>

    <div class="d-flex justify-content-end">
      <a-pagination
        :current="Number($route.query.page || 1)"
        :page-size="Number($route.query.limit || DEFAULT_PAGE_SIZE)"
        :page-size-options="[ '10', '25', '50' ]"
        :total="product.total_record"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        :disabled="isLoading"
        show-size-changer
        @change="(page) => $router.push({ query: { ...$route.query, page }})"
        @showSizeChange="(_current, limit) => $router.push({ query: { ...$route.query, page: 1, limit }})"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.store-product-list-page::v-deep tr td {
  padding: .5rem;
  font-size: 13px;

  &:first-child {
    padding-left: 1rem;
  }

  .product-title {
    font-weight: 500;
    color: var(--color-black);
  }
}
</style>
