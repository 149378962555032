var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"store-product-list-page"},[_c('h1',{staticClass:"h4"},[_vm._v(" "+_vm._s(`${_vm.$t('product.listProduct')}`)+" - WebCommerce ")]),_c('div',{staticClass:"row mt-4 mb-4"},[_c('div',{staticClass:"col-md-5"},[_c('a-input-search',{attrs:{"default-value":_vm.$route.query.q,"size":"large","placeholder":_vm.$t('store_list_page.search_products'),"loading":_setup.isLoading,"allow-clear":""},on:{"search":_setup.onSearch}},[_c('a-button',{attrs:{"slot":"enterButton","type":"danger","icon":"search"},slot:"enterButton"},[_vm._v(" "+_vm._s(_vm.$t('utils.search'))+" ")])],1)],1)]),_c('div',{staticClass:"bg-white mb-4",staticStyle:{"overflow-x":"auto"}},[_c('a-table',{staticClass:"mb-4",attrs:{"data-source":_setup.product.data,"row-key":(record, index) => `${record.product_id}_${index}`,"columns":_setup.columns,"loading":_setup.isLoading,"pagination":false},scopedSlots:_vm._u([{key:"showimg_url",fn:function(_text, record){return [_c('RouterLink',{staticClass:"d-flex align-items-center product-title",attrs:{"to":{
            name: 'channel.store.product.edit',
            params: { id: record.product_id },
            query: {
            ..._vm.$route.query,
            redirect_url: _vm.$route.fullPath,
            master_product: true,
            },
          }}},[_c('div',[_c('img',{staticClass:"product-image mr-3",attrs:{"src":`${record.thumbimg_url}?tr=w-50,h-50,cm-pad_resize,bg-FFFFFF`,"draggable":"false","width":"35","height":"35"},on:{"error":_setup.onImageError}})]),_c('div',[_vm._v(" "+_vm._s(record.title)+" ")])])]}},{key:"category_name",fn:function(text){return [_vm._v(" "+_vm._s(text || '-')+" ")]}},{key:"max_sellingprice",fn:function(_text, record){return [_c('div',[_vm._v(" "+_vm._s(_setup.priceLabel(record))+" ")])]}},{key:"status",fn:function(text){return [_c('a-tag',{attrs:{"color":_setup.colors[text]}},[_vm._v(" "+_vm._s(_vm.$t(`product.productStatus.${text}`))+" ")])]}},{key:"action",fn:function(record){return [_c('div',{staticClass:"text-right"},[_c('a-dropdown',{scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('a-menu',[_c('a-menu-item',{key:"2",staticClass:"py-2",on:{"click":function($event){return _vm.$router.push({
                    name: 'channel.store.product.edit',
                    params: { id: record.product_id },
                    query: {
                    ..._vm.$route.query,
                    redirect_url: _vm.$route.fullPath,
                    master_product: true,
                    },
                  })}}},[_vm._v(" Lihat Detail ")]),_c('a-menu-item',{key:"3",staticClass:"py-2",on:{"click":function($event){return _setup.showModalUpdate(record)}}},[_vm._v(" "+_vm._s(record.product_status === 'ACTIVE' || record.status === 'ACTIVE' ? _vm.$t('store_list_page.deactivate_product') : _vm.$t('store_list_page.activate_product'))+" ")])],1)]},proxy:true}],null,true)},[_c('a',{on:{"click":function($event){$event.preventDefault();}}},[_c('a-icon',{attrs:{"type":"more"}})],1)])],1)]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('a-pagination',{attrs:{"current":Number(_vm.$route.query.page || 1),"page-size":Number(_vm.$route.query.limit || _setup.DEFAULT_PAGE_SIZE),"page-size-options":[ '10', '25', '50' ],"total":_setup.product.total_record,"show-total":(total, range) => _vm.$t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total }),"disabled":_setup.isLoading,"show-size-changer":""},on:{"change":(page) => _vm.$router.push({ query: { ..._vm.$route.query, page }}),"showSizeChange":(_current, limit) => _vm.$router.push({ query: { ..._vm.$route.query, page: 1, limit }})}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }