import { i18n } from '@/i18n'

export default [
  {
    title: i18n.t('product.product_name'),
    dataIndex: 'showimg_url',
    key: 'showimg_url',
    width: 450,
    scopedSlots: {
      customRender: 'showimg_url',
    },
  },
  {
    title: i18n.t('product.categories'),
    dataIndex: 'category_name',
    key: 'category_name',
    width: 150,
    scopedSlots: {
      customRender: 'category_name',
    },
  },
  {
    title: i18n.t('product.varian'),
    dataIndex: 'variant_count',
    key: 'variant_count',
    width: 90,
    align: 'center',
    scopedSlots: {
      customRender: 'variant_count',
    },
  },
  {
    title: i18n.t('product.range_price'),
    dataIndex: 'max_sellingprice',
    key: 'max_sellingprice',
    width: 225,
    scopedSlots: {
      customRender: 'max_sellingprice',
    },
  },
  {
    title: i18n.t('product.status'),
    dataIndex: 'status',
    key: 'status',
    width: 90,
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: '',
    key: 'action',
    width: 50,
    align: 'right',
    scopedSlots: {
      customRender: 'action',
    },
  },
]
